import { Component, OnInit, Injectable } from '@angular/core';
import { Appointment, Priority } from '../app.service';
import { CalendarItem } from '../calendar-item';
import { Category } from '../category';
import DataSource from 'devextreme/data/data_source';
import CustomStore from 'devextreme/data/custom_store';
import { HttpClient } from '@angular/common/http';
import { BrowserDynamicTestingModule } from '@angular/platform-browser-dynamic/testing';
import { MTIDataUtilsModule } from '../mtidata-utils/mtidata-utils.module';
import { Resource } from '../resource';
import { TeamMembersService } from '../team-members.service';
import { CalenderQueryService } from '../calender-query.service';
import { NavigationStateService } from '../navigation-state.service';
import { CategoriesService } from '../categories.service';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';

@Component({
  selector: 'app-group-calendar',
  templateUrl: './group-calendar.component.html',
  styleUrls: ['./group-calendar.component.scss'],
})
export class GroupCalendarComponent implements OnInit {

  public height = 800;
  groupId: string;
  constructor(
    private calenderQueryService: CalenderQueryService,
    private teamMembersService: TeamMembersService,
    private navigationStateService: NavigationStateService,
    private categoriesService: CategoriesService,
    public router: Router,
    private route: ActivatedRoute

  ) {
    this.categories = this.categoriesService.categories;
    this.navigationStateService.TeamChange.subscribe( async (s) => {
      this.resourcesData = (await this.teamMembersService.getTeamMembers(this.groupId)).filter (f => s.includes(f.upn));
    });
  }
  myappointmentsData: CalendarItem[];
  categories: Category[];
  loading = false;
  dataSource: any;
  appointmentsData: Appointment[];
  // appointmentsData: CalendarItem[];
  resourcesData: Resource[];
  resourcesDataAll: Resource[];
  prioritiesData: Priority[];
  currentDate: Date = new Date( Date.now());

  lastDate: Date;

  async ngOnInit() {
    this.height = window.innerHeight - 120;

    this.route.paramMap.subscribe( async (params: ParamMap) => {
      this.groupId = params.get('groupId');
      this.resourcesDataAll = (await this.teamMembersService.getTeamMembers(this.groupId));
      this.resourcesData = this.resourcesDataAll.slice(0, 3);
      this.lastDate = new Date();

      this.dataSource = new DataSource({
        store: new CustomStore({
          // load: (options) => this.getData(options, { showDeleted: false }).then( t => console.log('t', t))
          load: (options) => this.getData(options, { showDeleted: false })
        })
      });

    });
  }

  private async getData(options: any, requestOptions: any) {
    this.lastDate = options.dxScheduler.startDate;
    this.myappointmentsData = await this.calenderQueryService.Calender(
      options.dxScheduler.startDate, options.dxScheduler.endDate, this.resourcesData, 3);

    const a = this.myappointmentsData.map( m => m.category);
    const unique = a.filter((item, i, ar) => ar.indexOf(item) === i);
    console.log('data', unique);

    this.myappointmentsData =
      MTIDataUtilsModule.ApplyMTICategories(this.myappointmentsData, this.resourcesDataAll, this.categories)
      .filter( f => f.category !== '#NA');



    return this.myappointmentsData;
  }
}


