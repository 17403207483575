import { BrowserModule } from '@angular/platform-browser';
import { NgModule, Injectable } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HttpClientModule, HttpHeaders } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MyNavComponent } from './my-nav/my-nav.component';
import { LayoutModule } from '@angular/cdk/layout';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatButtonModule } from '@angular/material/button';
import {MatButtonToggleModule} from '@angular/material/button-toggle';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { GroupCalendarComponent } from './group-calendar/group-calendar.component';


import {   DxPivotGridModule } from 'devextreme-angular/ui/pivot-grid';
import {   DxChartModule } from 'devextreme-angular/ui/chart';

import {  DxSchedulerModule } from 'devextreme-angular/ui/scheduler';
import { DxPieChartModule  } from 'devextreme-angular/ui/pie-chart';
import { DxCalendarModule  } from 'devextreme-angular/ui/calendar';

import { SummaryComponent } from './summary/summary.component';
import { ODataConfiguration, ODataServiceFactory } from 'angular-odata-es5';

import { FlexLayoutModule } from '@angular/flex-layout';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { Client, Options, AuthProvider, AuthProviderCallback } from '@microsoft/microsoft-graph-client';
import { NgxPrintModule } from './ngx-print.module';

export class ODataConfigurationFactory {

  constructor() {
      const config = new ODataConfiguration();
      config.baseUrl = 'https://graph.microsoft.com/beta/';

      config.defaultRequestOptions.headers = new HttpHeaders(
        { Authorization: 'Bearer ' + sessionStorage.getItem('adal.access.token.keyhttps://graph.microsoft.com') }
    );
      return config;
  }
}

export class MSGClientFactory {
  constructor() {
    const authProvider: AuthProvider = (callback: AuthProviderCallback) => {
      callback(null, sessionStorage.getItem('adal.access.token.keyhttps://graph.microsoft.com'));
    };
    const options: Options = {
      authProvider,
    };
    return Client.init(options);
  }
}

@Injectable()

@NgModule({
  declarations: [
    AppComponent,
    MyNavComponent,
    GroupCalendarComponent,
    SummaryComponent,
    PageNotFoundComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    BrowserAnimationsModule,
    LayoutModule,
    MatToolbarModule,
    MatButtonModule,
    MatSidenavModule,
    MatIconModule,
    MatListModule,
    MatButtonModule,
    MatButtonToggleModule,
    DxPieChartModule,
    DxSchedulerModule,
    DxPivotGridModule,
    DxChartModule,
    DxCalendarModule,
    FlexLayoutModule,
    NgxPrintModule
  ],
  providers: [
    { provide: ODataConfiguration , useFactory: ODataConfigurationFactory }, ODataServiceFactory,
    { provide: Client , useFactory: MSGClientFactory }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }


