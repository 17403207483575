<div id="pivotgrid-demo">  
    <dx-scheduler 
    [dataSource]="dataSource"
    [views]='[ "month"]'
    currentView="month"
    color='gray'
    height='35'
    [crossScrollingEnabled]="true"
    [firstDayOfWeek]="0"
    [startDayHour]="8"
    [endDayHour]="17"
    [cellDuration]="60"    
    [currentDate]="currentDate"
    [groups]="['userId']"
    useNative="true"
    ></dx-scheduler>

    <dx-chart class="noprint" id="sales-chart"  [customizePoint]="customizePoint">
      <dxo-common-series-settings type="bar"></dxo-common-series-settings>
      <dxo-size [height]="200"></dxo-size>
      <dxo-adaptive-layout [width]="450"></dxo-adaptive-layout>
    </dx-chart>
  <div id="p2"> 
    <dx-pivot-grid id="pivotgrid"
      
      [allowSortingBySummary]="true"
      [allowFiltering]="true"
      [showBorders]="true"
      [allowSortingBySummary]="false"
      [showColumnGrandTotals]="true"
      [showRowGrandTotals]="true"
      [showRowTotals]="true"
      [showColumnTotals]="true"
      [dataSource]="pivotGridDataSource">
      <dxo-header-filter
      [allowSearch]="true"
      [showRelevantValues]="true"
      [width]="300"
      [height]="400"
    ></dxo-header-filter>

      <dxo-field-panel
      [showFilterFields]="false"
      [visible]="true">
    </dxo-field-panel>

      <dxo-scrolling mode="virtual"></dxo-scrolling>
<!-- 
      <dxi-resource
      fieldExpr="categoryId"
      [allowMultiple]="false"
      [dataSource]="categories"
      label="Category"
      [useColorAsDefault]="true"
      ></dxi-resource>
   -->

    </dx-pivot-grid>
  </div>
  </div>  