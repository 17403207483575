import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { GroupCalendarComponent } from './group-calendar/group-calendar.component';
import { SummaryComponent } from './summary/summary.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';

const routes: Routes = [
  { path: 'GroupCalendar/:groupId', component: GroupCalendarComponent },
  { path: 'Summary/:groupId/:viewBy', component: SummaryComponent },
  { path: 'Summary/:groupId', component: SummaryComponent },
  { path: '', redirectTo: '/Summary/a2d805ea-990d-40b7-8a44-61c3cfb84181/user', pathMatch: 'full'},
  { path: '**', component: PageNotFoundComponent }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes

    )
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
