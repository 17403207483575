import { Component, OnInit } from '@angular/core';
import { Client, AuthProvider, AuthProviderCallback, Options } from '@microsoft/microsoft-graph-client';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'Team Calender View';

  async ngOnInit() {
    try {

      const authProvider: AuthProvider = (callback: AuthProviderCallback) => {
        callback(null, sessionStorage.getItem('adal.access.token.keyhttps://graph.microsoft.com'));
      };

      console.log('start');

      const options: Options = {
        authProvider,
      };
      const client = Client.init(options);
      const userDetails = await client.api('/me').get();
      console.log('user', userDetails);
    } catch (error) {
      throw error;
    }
  }


}
