<mat-sidenav-container class="sidenav-container">
  <mat-sidenav #drawer class="sidenav noprint" fixedInViewport="true"
    [attr.role]="(isHandset$ | async) ? 'dialog' : 'navigation'" [mode]="(isHandset$ | async) ? 'over' : 'side'"
    [opened]="!(isHandset$ | async)">
    <mat-toolbar>Menu</mat-toolbar>
    <mat-nav-list style="overflow-x: hidden">
      <a mat-list-item routerLinkActive='active'>Select a group</a>
      <mat-list-item>
        <a [class.selected]="groupId == 'a2d805ea-990d-40b7-8a44-61c3cfb84181'"
          [routerLink]="[scope,'a2d805ea-990d-40b7-8a44-61c3cfb84181']" mat-list-item>Sales</a>
      </mat-list-item>
      <mat-list-item>
        <a [class.selected]="groupId == '724b6e63-84aa-412b-b3c0-d6c83915bf18'"
          [routerLink]="[scope,'724b6e63-84aa-412b-b3c0-d6c83915bf18']" mat-list-item>Pre Sales</a>
      </mat-list-item>
      <a [class.active]="scope=='Summary'" mat-list-item [routerLink]="['Summary', groupId, 'user']">Summary</a>
      <mat-list-item>
        <a [class.selected]="by=='user'" mat-list-item [routerLink]="['Summary', groupId, 'user']">By user</a>
      </mat-list-item>
      <mat-list-item>
        <a [class.selected]="by=='manager'" mat-list-item [routerLink]="['Summary', groupId, 'manager']">By manager</a>
      </mat-list-item>
      <a [class.active]="scope=='GroupCalendar'" mat-list-item [routerLink]="['GroupCalendar', groupId]">Calender</a>
      <mat-list-item *ngIf='router && router.url.startsWith("/GroupCalendar")' class='hidden' routerLinkActive='hidden'>
        <h3 matSubheader>Select up to 5 team members</h3>
      </mat-list-item>
      <mat-list *ngIf='router && router.url.startsWith("/GroupCalendar")' dense>
        <mat-list-item *ngFor='let team of teams' [class.selected]='teamSelected(team.upn)'>
          <a (click)='appendTeam(team.upn)' mat-list-item>{{team.text}}</a>
        </mat-list-item>
      </mat-list>
    </mat-nav-list>
  </mat-sidenav>
  <mat-sidenav-content>
    <mat-toolbar color="primary">
      <button type="button" aria-label="Toggle sidenav" mat-icon-button (click)="drawer.toggle()"
        *ngIf="isHandset$ | async">
        <mat-icon aria-label="Side nav toggle icon">menu</mat-icon>
      </button>
      <span>Team Calender View</span>
      <span class="fill-remaining-space"></span>
      <button mat-button
        [useExistingCss]="true" 
        styleSheetFile="/assets/print.css"
        [printDelay]=1000
        printSectionId="p2" ngxPrint>Print&nbsp;<mat-icon>print</mat-icon></button>
    </mat-toolbar>
    <div id="printme">
      <router-outlet></router-outlet>
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>