import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { MTIDataUtilsModule } from '../mtidata-utils/mtidata-utils.module';
import { Category } from '../category';
import { DxPivotGridComponent } from 'devextreme-angular/ui/pivot-grid';
import { DxChartComponent } from 'devextreme-angular/ui/chart';
import { TeamMembersService } from '../team-members.service';
import { Resource } from '../resource';
import { CalenderQueryService } from '../calender-query.service';
import { CategoriesService } from '../categories.service';
import DataSource from 'devextreme/data/data_source';
import CustomStore from 'devextreme/data/custom_store';
import * as moment from 'moment';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';


@Component({
  selector: 'app-summary',
  templateUrl: './summary.component.html',
  styleUrls: ['./summary.component.scss']
})
export class SummaryComponent implements OnInit, AfterViewInit {
  @ViewChild(DxPivotGridComponent) pivotGrid: DxPivotGridComponent;
  @ViewChild(DxChartComponent) chart: DxChartComponent;

  byUser = [
    {
      caption: 'User',
      dataField: 'user',
      area: 'row',
      sortBySummaryField: 'Total',
    },
    {
      caption: 'Category',
      dataField: 'category',
      width: 150,
      area: 'row',
      color: 'red',
      filterValues: ['Customer Meeting']
    },
    {
      caption: 'Week Commencing (Month Day)',
      width: 120,
      dataField: 'weekCommencing',
      area: 'column',
    },
    {
      caption: 'Number of meetings',
      dataField: 'duration',
      dataType: 'number',
      summaryType: 'count',
      calculateSummaryValue(e) {
        return e.value() || 0;
      },
      area: 'data',
      format: { type: 'fixedPoint', precision: 0 }
    }];
  byManager = [
    {
      caption: 'Manager',
      width: 120,
      dataField: 'manager',
      area: 'row',
      sortBySummaryField: 'Total',
    }, ...this.byUser
  ];

  pivotGridDataSource: any;
  groupId: any;
  viewBy: any;
  constructor(
    private teamMembersService: TeamMembersService,
    private calenderQueryService: CalenderQueryService,
    private categoriesService: CategoriesService,
    public router: Router,
    private route: ActivatedRoute

  ) {
    this.categories = this.categoriesService.categories;
    console.log('categories', this.categories);
  }
  public categories: Category[];
  resourcesData: Resource[];
  currentDate: Date = new Date();
  dataSource: any;

  ngAfterViewInit() {
    this.pivotGrid.instance.bindChart(this.chart.instance, {
      dataFieldsDisplayMode: 'splitPanes',
      alternateDataFields: false
    });
  }

  private async getData(options: any, requestOptions: any) {
    // tslint:disable-next-line: one-variable-per-declaration
    const date = moment(options.dxScheduler.startDate).add(10, 'd').toDate();

    // const firstDay = moment(date).startOf('month').subtract(1, 'week').toDate();
    // const lastDay = moment(date).endOf('month').add(1, 'week').toDate();

    const firstDay = moment(date).startOf('month').toDate();
    const lastDay = moment(date).endOf('month').toDate();


    console.log('startDate', firstDay);
    console.log('startDate m', moment(firstDay).format('YYYY-MM-DDTHH:mm:ss'));
    console.log('startDate ISO', firstDay.toISOString());
    console.log('startDate UTC', firstDay.toUTCString());

    console.log('resourcesData', this.resourcesData);

    let data =
      MTIDataUtilsModule.ApplyMTICategories(
        await this.calenderQueryService.Calender(firstDay, lastDay, this.resourcesData, 3),
        this.resourcesData, this.categories
      ).filter(f => f.category !== '#NA')
        .sort((a, b) => a.week > b.week ? 1 : -1);


    const monday = moment(firstDay)
        .startOf('month')
        .day('Monday');
    if (monday.date() > 7) { monday.add(7, 'd'); }
    const month = monday.month();
    while (month === monday.month()) {
      data = data.concat(this.resourcesData.map((m) =>
       ({ user: m.text, manager: m.manager, weekCommencing: monday.format('MM DD'), category: 'Customer Meeting' })));
      monday.add(7, 'd');
    }
    console.log('data', data);
    this.pivotGridDataSource = {
      fields: this.viewBy === 'user' ? this.byUser : this.byManager,
      store: data
    };
    return null;
  }

  async ngOnInit() {
    const date = new Date();

    this.route.paramMap.subscribe(async (params: ParamMap) => {
      this.groupId = params.get('groupId');
      this.viewBy = params.get('viewBy') || 'user';
      this.resourcesData = await this.teamMembersService.getTeamMembers(this.groupId);
      this.dataSource = new DataSource({
        store: new CustomStore({
          load: (options) => this.getData(options, { showDeleted: false })
        })
      });
    });
  }

  customizePoint = (arg: any) => {
    const seriesParts = arg.seriesName.split('-');
    if (seriesParts.length > 1) {
      const category = this.categories.find((f) => f.text === seriesParts[1].trim());
      if (category) {
        return {
          border: { visible: true, width: 3, color: category.color },
          hoverStyle: { visible: true, width: 3, color: category.color }
        };
      }
    }
  }
}
