<dx-scheduler 
    [dataSource]="dataSource"
    [views]='["agenda","timelineDay", "timelineWeek", "timelineWorkWeek", "timelineMonth"]'
    currentView="timelineMonth"
    color='gray'
    height='{{height}}'
    [crossScrollingEnabled]="true"
    [firstDayOfWeek]="0"
    [startDayHour]="8"
    [endDayHour]="17"
    [cellDuration]="60"    
    [currentDate]="currentDate"
    [groups]="['userId']"
    useNative="true"
    >
    <dxo-editing #editingOptions
    [allowAdding]="false"
    [allowUpdating]="false"
    [allowDeleting]="false"
    [allowResizing]="false"
    [allowDragging]="false"
></dxo-editing>
    <dxi-resource
    fieldExpr="categoryId"
    [allowMultiple]="false"
    [dataSource]="categories"
    label="Category"
    [useColorAsDefault]="true"
    ></dxi-resource>
    <dxi-resource
    fieldExpr="userId"
    [allowMultiple]="false"
    [dataSource]="resourcesData"
    label="Owner"
    >
    <dxi-view
    name="Auto Mode"
    type="month"
    maxAppointmentsPerCell="auto">
</dxi-view>
<dxi-view 
    name="Unlimited Mode"
    type="month"
    maxAppointmentsPerCell="unlimited">
</dxi-view>
<dxi-view 
    name="Numeric Mode"
    type="month"
    [maxAppointmentsPerCell]="2">
</dxi-view>
</dxi-resource>  
  
</dx-scheduler>
<div  color="primary">
        <div fxLayout="row" fxLayout.xs="column" fxLayoutWrap fxLayoutGap="0.5%" >
    <Legend *ngFor='let item of categories' [style.border-left-color]='item.color' style="border-left: 6px solid;padding-left:6px">
        {{item.text}}
    </Legend>
        </div>
</div>

<!-- [groups]="['userId']" -->