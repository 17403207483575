import { Injectable } from '@angular/core';
import { ODataServiceFactory } from 'angular-odata-es5';
import { Resource } from './resource';
import { CalendarItem } from './calendar-item';

@Injectable({
  providedIn: 'root'
})
export class CalenderQueryService {

  constructor(private odataFactory: ODataServiceFactory) {
  }
  async Calender(startDate: Date, endDate: Date, resources: Resource[], limit: number): Promise<CalendarItem[]> {
    const rval: CalendarItem[] = [];
    // for (const resource of resources.slice(0, limit)) {
    await Promise.all(resources.map(async (resource) => {
      try {
        const calendar = this.odataFactory.
          CreateService(`users/${resource.upn}/calendarview?startdatetime=${startDate.toISOString()}&enddatetime=${endDate.toISOString()}`);
        const result = await calendar
          .Query()
          .Top(5000)
          .Select('subject,categories,start,end').Exec().toPromise<any>();

        for (const item of result) {
          rval.push
            // tslint:disable-next-line: max-line-length
            ({ text: item.subject, category: item.categories[0], startDate: item.start.dateTime, endDate: item.end.dateTime, userId: resource.id });
        }
      } catch {
        console.log('error loading user');
      }
    }));

    return rval;

  }
}
