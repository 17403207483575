import { Injectable } from '@angular/core';
import { Category } from './category';

@Injectable({
  providedIn: 'root'
})
export class CategoriesService {

  public categories: Category[] = [
    {
      code: 'ICM',
      text: 'Internal Customer Meeting',
      id: 0,
      color: 'Green'
    }, {
      code: 'IVM',
      text: 'Internal Vendor Meeting',
      id: 1,
      color: 'Orange'
    }, {
      code: 'CM',
      text: 'Customer Meeting',
      id: 3,
      color: 'Red'
    },
    {
    code: 'VM',
      text: 'Vender Meeting',
      id: 4,
      color: 'Purple'
    }
  ];
  constructor() {

  }
}
