import { Component, OnInit, OnDestroy } from '@angular/core';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Observable, of } from 'rxjs';
import { map, share, filter, switchMap, distinct, throwIfEmpty } from 'rxjs/operators';
import { TeamMembersService } from '../team-members.service';
import { Resource } from '../resource';
import { Router, NavigationEnd, ActivatedRoute, ParamMap } from '@angular/router';
import { NavigationStateService } from '../navigation-state.service';

@Component({
  selector: 'app-my-nav',
  templateUrl: './my-nav.component.html',
  styleUrls: ['./my-nav.component.scss']
})
export class MyNavComponent  implements OnInit, OnDestroy {
  groupId: string;
  scope: any;
  by: string;

  constructor(
    private breakpointObserver: BreakpointObserver,
    private teamMembersService: TeamMembersService,
    public router: Router,
    private route: ActivatedRoute,
    private navigationStateService: NavigationStateService
    ) {
    this.selectedTeams = [];
  }
  teams: Resource[];
  selectedTeams: string[];

  navigationEnd: Observable<NavigationEnd>;
  routePathParam: Observable<string>;

  isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset)
    .pipe(
      map(result => result.matches),
      share()
    );

  ngOnDestroy(): void {

  }

  appendTeam(member: string) {
    if (this.selectedTeams.includes(member)) {
      this.selectedTeams.splice(this.selectedTeams.indexOf(member), 1 );
      this.navigationStateService.TeamChange.next(this.selectedTeams);
      return;
    }
    this.selectedTeams.push(member);
    this.selectedTeams = this.selectedTeams.slice(-5);

    this.navigationStateService.TeamChange.next(this.selectedTeams);

    // this.router.navigate(['/GroupCalendar'], { queryParams: { members: this.selectedTeams}});
  }

  teamSelected(member: string) {
    return this.selectedTeams.includes(member);
  }

  async ngOnInit() {

    console.log('init');
    this.navigationEnd = this.router.events.pipe(
      filter(event => event instanceof NavigationEnd), map( (event) => event as NavigationEnd )
      );

    this.routePathParam = this.navigationEnd
      .pipe(
        map( () => this.route.root),
        map( root => root.firstChild),
        switchMap( firstChild => {
          if (firstChild) {
            firstChild.url.subscribe( (s) => {
              this.by =  s[2]?.path;
              this.scope =  s[0].path;
            });
            console.log('switchMap', firstChild);
            return firstChild.paramMap.pipe(map(paramMap => paramMap.get('groupId')));
          } else {
            return of(null);
          }
        }),
      );

    this.routePathParam
      // .pipe(distinct())
      .subscribe( async s => {
        this.groupId = s;
        this.selectedTeams = [];
        this.teams = await this.teamMembersService.getTeamMembers(s);

      });
  }
}
