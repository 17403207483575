import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CalendarItem } from '../calendar-item';
import { Category } from '../category';
import { Resource } from '../resource';
import * as moment from 'moment';

@NgModule({
  declarations: [],
  imports: [
    CommonModule
  ]
})
export class MTIDataUtilsModule {


  static Acronym(input: string): string {
    const inputCopy = input;
    try {
      if (!input) { return undefined; }
      if (input.trim() === '') { return undefined; }
      input = input.replace('MTI', '');
      input = input.replace('onsite', 'Site 1');
      input = input.replace('offsite', 'Site 2');
      const matches = input.match(/\b(\w)/g);
      if (input.trim() === '') { return undefined; }
      return matches.join('').toUpperCase();
    } catch (e) {
      console.log(e);
      throw e;
    }
  }
  static ApplyMTICategories(input: CalendarItem[], resource: Resource[], categories: Category[]): CalendarItem[] {
    try {
      input.
        forEach(f => {
          try {
            const startDate = new Date(f.startDate);
            const endDate = new Date(f.endDate);
            const onejan = new Date(startDate.getFullYear(), 0, 1);

            f.duration = ((endDate).valueOf() - (startDate).valueOf()) / 1000 / 3600;
            // tslint:disable-next-line: radix
            f.week = parseInt(moment(f.startDate).format('w'));
            f.weekStart = moment( startDate).startOf('isoWeek').toDate();
            f.weekCommencing = moment(f.weekStart).format('MM DD');
            f.categoryId = categories.map(m => m.code).indexOf(MTIDataUtilsModule.Acronym(f.category));
            f.category = f.categoryId > 0 ? categories[f.categoryId].text : '#NA';
            f.user = resource[f.userId].text;
            f.manager = resource[f.userId].manager;
          } catch (e) {
            console.log(e);
            throw e;
          }
        });
      return input;
    } catch (e) {
      console.log(e);
      throw e;
    }
  }

}
