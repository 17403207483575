import { Injectable } from '@angular/core';
import { Resource } from './resource';
import { HttpClient } from '@angular/common/http';
import { ODataConfiguration, ODataServiceFactory, ODataService } from 'angular-odata-es5';
import { validateBasis } from '@angular/flex-layout';
import { AuthProvider, Options, Client, AuthProviderCallback } from '@microsoft/microsoft-graph-client';


@Injectable({
  providedIn: 'root'
})


export class TeamMembersService {

  teamCache: any = {};

  constructor(private http: HttpClient, private odataFactory: ODataServiceFactory, private client: Client) { }
  // a2d805ea-990d-40b7-8a44-61c3cfb84181
  async getTeamMembers(teamId: string): Promise<Resource[]> {

    console.log('getTeamMembers', teamId);

    if (this.teamCache[teamId]) {
      console.log('Cache', teamId, this.teamCache[teamId]);
      return this.teamCache[teamId];
    }

    const groups = this.odataFactory.CreateService(`groups/${teamId}/transitiveMembers`);
    const result = await groups.Query().Select('displayName,userPrincipalName').Exec().toPromise<any>();

    const rval = result
      .filter(f => f.userPrincipalName)
      .sort((a, b) => a.displayName.localeCompare(b.displayName))
      .map((v, i) => {
        return { id: i, text: v.displayName, upn: v.userPrincipalName, manager: '#None' };
      });

    // rval.push( ({ id: 7, text: 'Steve Drake', upn: 'SDrake@mti.com', manager: '#None' }));

    for (const val of rval) {
      try {
        const userDetails = await this.client.api(`users/${val.upn}/manager`).get();
        if (userDetails) {
          val.manager = userDetails.displayName;
        }
      } catch {
        console.log('error reading manager');
      }
    }
    this.teamCache[teamId] = rval;
    return rval;
  }
}
