import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class NavigationStateService {
  public TeamChange: Subject<string[]>;
  constructor() {
    this.TeamChange = new Subject();
  }
}
